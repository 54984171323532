<template>
    <modal ref="modalDuplicarKit" titulo="Duplicar Kit" icon="duplicate" tamano="modal-lg" no-aceptar adicional="Duplicar Kit" @adicional="duplicarKit">
        <div class="row mx-0 justify-center">
            <div class="col-8">
                <p class="pl-3 text-general f-14">
                    Seleccione el cedis donde se duplicará
                </p>
                <input-select-cedis @cedis_seleccionado="buscarNoDisponibles" :seleccionado="Number(cedis_seleccionado)" />
            </div>
        </div>
        <div class="row mx-0 justify-center pt-4">
            <div v-for="producto,index in kit.productos" :key="index" class="col-8 d-flex mb-3" id="loading-duplicar">
                <img :src="producto.info.foto" class="obj-cover" width="89" height="89" />
                <div class="col-6 pr-0">
                    <p class="text-general nombre">
                        {{producto.info.nombre}}
                    </p>
                    <p class="text-general2 mb-2">
                        {{producto.info.presentacion}}
                    </p>
                    <div class="row mx-0 mb-2">
                        <p class="col-auto px-0 text-general f-14">
                            {{ convertMoney(producto.precio, id_moneda) }}
                        </p>
                        <p class="col-auto descuento text-gris f-14">
                            <!-- $5.000 -->
                        </p>
                    </div>
                    <div class="row mx-0">
                        <div class="bg-general text-white px-3 br-10 f-12">
                           <!-- DESCUENTO -->
                        </div>
                    </div>
                </div>
                <div v-if="noEsDisponible(producto.id_producto)" class="col-auto ml-auto px-0">
                    <div class="bg-light-f5 text-general2 px-2 br-5 pt-3 position-relative">
                        <div class="position-absolute bg-white rounded-circle p-1" style="left:39px;top:-21px;">
                            <i class="icon-info-circled-alt text-general-red f-20" />
                        </div>
                        No se vende <br /> en este cedis
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import CedisKits from '~/services/cedis/cedis_kits'
import {mapGetters} from 'vuex'
export default {
    data(){
        return{            
            kit : {},
            value: '',
            no_disponibles:[],
            disponibles:[],
            cedis_seleccionado:1,
            url_image: 'https://cdn1.totalcode.net/la14/product-zoom/es/m%26m-s-plain-chocolate-candies-x-und-x--47.9g-1.webp'
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    methods: {
        toggle(kit = {}){
            this.kit = kit
            this.cedis_seleccionado = this.$route.params.id_cedis
            this.$refs.modalDuplicarKit.toggle()
        },
        async buscarNoDisponibles(cedis){
            this.cedis_seleccionado = cedis
            try{
                let loading = this.$loading(
                {
                    lock: true,
                    target:`#loading-duplicar`
                });
                
                let datos = {
                    productos : _.map(this.kit.productos, 'id_producto')
                }
                const { data } = await CedisKits.BuscarNoDisponibles(cedis,datos)
                this.no_disponibles = data
                loading.close()
            }catch (error){
                this.error_catch(error)
            }
        },
        noEsDisponible(id_producto){       
            return ! (this.no_disponibles.indexOf(id_producto.toString()) == -1)
        },
        async duplicarKit(){
            
            try{
                this.setDisponibles()
                let loading = this.$loading(
                {
                    lock: true,
                    target:`#loading-duplicar`
                });
                
                let datos = {
                    id_cedis: this.cedis_seleccionado,
                    kit : this.kit,
                    productos : this.disponibles
                }
                const { data } = await CedisKits.DuplicarKit(datos)
                
                this.notificacion('Kit Duplicado', 'El kit ha sido duplicado exitosamente', 'success')
                this.toggle();
                loading.close()
            }catch (error){
                this.error_catch(error)
            }
        },
        setDisponibles(){
            this.disponibles = []
            for (let i = 0; i < this.kit.productos.length; i++) {
                if((this.no_disponibles.indexOf(this.kit.productos[i].id_producto.toString()) == -1)){
                    this.disponibles.push(this.kit.productos[i].id_producto)
                }
                
            }
        }
    }
}
</script>